export const environment = {
  get agent() {
    return (
      window.env.agent +
      (window.env.namespace ? '/' + window.env.namespace : '')
    );
  },
  get backend() {
    return window.env.agent;
  },
  get wallet() {
    return window.env.wallet;
  },
  get keycloakUrl() {
    return window.env.keycloakUrl;
  },
  get keycloakRealm() {
    return window.env.keycloakRealm;
  },
  get keycloakClientId() {
    return window.env.keycloakClientId;
  },
};
